import React, { useState } from 'react';
import { Mail, Lock, User, ArrowRight, X, Car, Phone } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { COUNTRY_CODES } from '../lib/constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function Auth({ isOpen, onClose }: Props) {
  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+34');
  const [error, setError] = useState<string | null>(null);
  const { signIn, signUp } = useAuth();

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    try {
      setLoading(true);
      if (isSignUp) {
        const formattedPhone = phone ? `${countryCode} ${phone}` : '';
        await signUp(email, password, fullName, formattedPhone);
      } else {
        await signIn(email, password);
      }
      onClose();
    } catch (err) {
      console.error('Auth error:', err);
      setError(err instanceof Error ? err.message : 'Ha ocurrido un error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="max-w-md w-full space-y-8 bg-gray-800/90 backdrop-blur-sm p-8 rounded-xl border-2 border-[#03E888]/50">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <Car className="w-8 h-8 text-[#03E888]" />
            <div>
              <h2 className="text-2xl font-bold text-white font-mono">GroupRides DANA</h2>
              <p className="text-sm text-gray-400 font-mono">
                {isSignUp ? 'Crea tu cuenta para comenzar' : 'Inicia sesión para continuar'}
              </p>
            </div>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>

        {error && (
          <div className="p-3 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-4">
            {isSignUp && (
              <>
                <div>
                  <label htmlFor="fullName" className="sr-only">
                    Nombre completo
                  </label>
                  <div className="relative">
                    <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                    <input
                      id="fullName"
                      type="text"
                      required
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] placeholder-gray-400 font-mono"
                      placeholder="Nombre completo"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="phone" className="sr-only">
                    Teléfono
                  </label>
                  <div className="flex gap-2">
                    <div className="relative">
                      <select
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="pl-3 pr-8 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] font-mono appearance-none"
                      >
                        {COUNTRY_CODES.map(({ code }) => (
                          <option key={code} value={code}>
                            {code}
                          </option>
                        ))}
                      </select>
                      <div className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                    <div className="relative flex-1">
                      <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                      <input
                        id="phone"
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="w-full pl-10 pr-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] placeholder-gray-400 font-mono"
                        placeholder="123456789"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div>
              <label htmlFor="email" className="sr-only">
                Correo electrónico
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  id="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] placeholder-gray-400 font-mono"
                  placeholder="tu@email.com"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="sr-only">
                Contraseña
              </label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  id="password"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-10 pr-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] placeholder-gray-400 font-mono"
                  placeholder="••••••••"
                  minLength={6}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="group relative w-full flex justify-center py-3 px-4 bg-[#03E888] hover:bg-[#02c974] text-gray-900 rounded-lg transition-colors duration-300 font-medium font-mono disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              'Cargando...'
            ) : (
              <>
                {isSignUp ? 'Crear cuenta' : 'Iniciar sesión'}
                <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
              </>
            )}
          </button>
        </form>

        <div className="text-center">
          <button
            onClick={() => setIsSignUp(!isSignUp)}
            className="text-[#03E888] hover:text-[#02c974] transition-colors font-mono text-sm"
          >
            {isSignUp ? '¿Ya tienes cuenta? Inicia sesión' : '¿No tienes cuenta? Regístrate'}
          </button>
        </div>
      </div>
    </div>
  );
}