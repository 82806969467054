import React, { useState, useEffect } from 'react';
import { Car, LogOut, Building2, Users } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { UniversityList } from './UniversityList';
import { GroupList } from './GroupList';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import ProfilePage from './ProfilePage';
import { useProfile } from '../hooks/useProfile';
import { DonationButton } from './donation/DonationButton';
import { DonationModal } from './donation/DonationModal';

interface Props {
  session: { user: any };
  onAuthRequired: () => void;
}

type Tab = 'universities' | 'groups';

export function Dashboard({ session: { user }, onAuthRequired }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useProfile(user);
  const { signOut } = useAuth();
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [showDonationButton, setShowDonationButton] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>('groups');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsHeaderVisible(currentScrollY <= 0 || currentScrollY < lastScrollY);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDonate = () => {
    window.open('https://donate.stripe.com/aEUbLmfxS0SGetObIL', '_blank');
    setShowDonationModal(false);
  };

  if (location.pathname === '/profile') {
    return (
      <div className="min-h-screen bg-gray-900 flex flex-col">
        <header className="bg-gray-800/50 backdrop-blur-sm border-b border-gray-700/50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <Link to="/" className="flex items-center gap-3 hover:opacity-80 transition-opacity">
              <Car className="w-8 h-8 text-[#03E888]" />
              <div>
                <h1 className="text-2xl font-bold text-white font-mono">GroupRides DANA</h1>
                <p className="text-sm text-gray-400 font-mono">Carpooling en Grupos</p>
              </div>
            </Link>
          </div>
        </header>
        <ProfilePage />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <header 
        className={`bg-gray-800/50 backdrop-blur-sm border-b border-gray-700/50 sticky top-0 z-10 transition-transform duration-300 ${
          isHeaderVisible ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center gap-3 hover:opacity-80 transition-opacity">
              <Car className="w-8 h-8 text-[#03E888]" />
              <div>
                <h1 className="text-2xl font-bold text-white font-mono">GroupRides DANA</h1>
                <p className="text-sm text-gray-400 font-mono">Carpooling en Grupos</p>
              </div>
            </Link>

            {user ? (
              <Link
                to="/profile"
                className="flex items-center gap-3 hover:opacity-80 transition-opacity"
              >
                <div className="text-right hidden sm:block">
                  <p className="text-white font-mono">{profile?.full_name}</p>
                  <p className="text-sm text-gray-400 font-mono">Mi Perfil</p>
                </div>
                <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-[#03E888]/50">
                  {profile?.avatar_url ? (
                    <img
                      src={profile.avatar_url}
                      alt={profile.full_name}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-700 flex items-center justify-center">
                      <Car className="w-5 h-5 text-gray-400" />
                    </div>
                  )}
                </div>
              </Link>
            ) : (
              <button
                onClick={onAuthRequired}
                className="px-4 py-1.5 bg-[#03E888] hover:bg-[#02c974] text-gray-900 rounded-lg transition-colors duration-300 font-medium font-mono text-sm"
              >
                Iniciar sesión
              </button>
            )}
          </div>

          <div className="mt-4 flex justify-center">
            <div className="flex p-1 bg-gray-700/50 rounded-lg border border-gray-600">
              <button
                onClick={() => setActiveTab('groups')}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors font-mono ${
                  activeTab === 'groups'
                    ? 'bg-[#03E888] text-gray-900'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <Users className="w-4 h-4" />
                <span>Grupos</span>
              </button>
              <button
                onClick={() => setActiveTab('universities')}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors font-mono ${
                  activeTab === 'universities'
                    ? 'bg-[#03E888] text-gray-900'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <Building2 className="w-4 h-4" />
                <span>Universidades</span>
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className="flex-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {activeTab === 'universities' ? (
          <UniversityList user={user} onAuthRequired={onAuthRequired} />
        ) : (
          <GroupList user={user} onAuthRequired={onAuthRequired} />
        )}
      </main>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 space-y-4">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          {user && (
            <button
              onClick={handleSignOut}
              className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gray-800 hover:bg-gray-700 text-white px-6 py-3 rounded-lg transition-all duration-300 font-medium font-mono border-2 border-[#03E888]/20 hover:border-[#03E888]/50"
            >
              <LogOut className="w-5 h-5" />
              Cerrar sesión
            </button>
          )}

          {showDonationButton && (
            <DonationButton onClick={() => setShowDonationModal(true)} />
          )}
        </div>
      </div>

      <DonationModal
        isOpen={showDonationModal}
        onClose={() => setShowDonationModal(false)}
        onConfirm={handleDonate}
      />
    </div>
  );
}