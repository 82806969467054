import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { SocialLinkInput } from './SocialLinkInput';
import type { Database } from '../lib/database.types';

type Group = Database['public']['Tables']['groups']['Row'];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (group: { 
    name: string; 
    description: string; 
    color: string;
    whatsapp_url?: string;
    telegram_url?: string;
  }) => Promise<void>;
  initialData?: Group | null;
}

const PRESET_COLORS = [
  '#03E888', // Default green
  '#FF6B6B', // Coral red
  '#4ECDC4', // Turquoise
  '#45B7D1', // Sky blue
  '#9B59B6', // Purple
  '#F1C40F', // Yellow
  '#E67E22', // Orange
  '#2ECC71', // Emerald
];

export function AddGroupModal({ isOpen, onClose, onAdd, initialData }: Props) {
  const [form, setForm] = useState({
    name: '',
    description: '',
    color: '#03E888',
    whatsapp_url: '',
    telegram_url: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setForm({
        name: initialData.name,
        description: initialData.description,
        color: initialData.color,
        whatsapp_url: initialData.whatsapp_url || '',
        telegram_url: initialData.telegram_url || ''
      });
    } else {
      setForm({
        name: '',
        description: '',
        color: '#03E888',
        whatsapp_url: '',
        telegram_url: ''
      });
    }
  }, [initialData]);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await onAdd({
        ...form,
        whatsapp_url: form.whatsapp_url || undefined,
        telegram_url: form.telegram_url || undefined
      });
      onClose();
    } catch (err) {
      console.error('Error managing group:', err);
      setError(err instanceof Error ? err.message : 'Error al gestionar grupo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800/90 border-2 border-[#03E888]/50 rounded-xl max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-white font-mono">
            {initialData ? 'Editar Grupo' : 'Añadir Grupo'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
              Nombre del Grupo
            </label>
            <input
              type="text"
              required
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              className="w-full px-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] placeholder-gray-400 font-mono"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
              Descripción
            </label>
            <textarea
              required
              value={form.description}
              onChange={(e) => setForm({ ...form, description: e.target.value })}
              className="w-full px-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] placeholder-gray-400 font-mono"
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
              Enlaces de grupo (opcional)
            </label>
            <div className="space-y-2">
              <SocialLinkInput
                platform="whatsapp"
                value={form.whatsapp_url}
                onChange={(value) => setForm({ ...form, whatsapp_url: value })}
              />
              <SocialLinkInput
                platform="telegram"
                value={form.telegram_url}
                onChange={(value) => setForm({ ...form, telegram_url: value })}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
              Color del Grupo
            </label>
            <div className="space-y-3">
              <div className="grid grid-cols-8 gap-2">
                {PRESET_COLORS.map((color) => (
                  <button
                    key={color}
                    type="button"
                    className={`w-8 h-8 rounded-lg border-2 transition-all duration-300 ${
                      form.color === color ? 'border-white scale-110' : 'border-transparent hover:scale-110'
                    }`}
                    style={{ backgroundColor: color }}
                    onClick={() => setForm({ ...form, color })}
                  />
                ))}
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="color"
                  value={form.color}
                  onChange={(e) => setForm({ ...form, color: e.target.value })}
                  className="w-8 h-8 rounded bg-transparent cursor-pointer"
                />
                <input
                  type="text"
                  value={form.color}
                  onChange={(e) => setForm({ ...form, color: e.target.value })}
                  pattern="^#[0-9A-Fa-f]{6}$"
                  className="flex-1 px-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] font-mono"
                  placeholder="#000000"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 px-4 bg-[#03E888] hover:bg-[#02c974] text-gray-900 rounded-lg transition-colors duration-300 font-medium font-mono disabled:opacity-50"
          >
            {loading ? 'Procesando...' : initialData ? 'Guardar Cambios' : 'Añadir Grupo'}
          </button>
        </form>
      </div>
    </div>
  );
}