import React, { useState } from 'react';
import { Plus, Building2, Linkedin } from 'lucide-react';
import { UniversityCard } from './UniversityCard';
import { AddUniversityModal } from './AddUniversityModal';
import { CarList } from './CarList';
import { useUniversities } from '../hooks/useUniversities';
import { useProfile } from '../hooks/useProfile';
import { useFeatures } from '../contexts/FeatureContext';
import { SearchInput } from './SearchInput';
import type { Database } from '../lib/database.types';

type University = Database['public']['Tables']['universities']['Row'] & {
  ride_count?: number;
};

interface Props {
  user: any;
  onAuthRequired: () => void;
}

export function UniversityList({ user, onAuthRequired }: Props) {
  const { universities, loading, error, addUniversity, updateUniversity } = useUniversities();
  const { profile } = useProfile(user);
  const { features } = useFeatures();
  const [selectedUniversity, setSelectedUniversity] = useState<string | null>(null);
  const [showAddUniversity, setShowAddUniversity] = useState(false);
  const [editingUniversity, setEditingUniversity] = useState<University | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleAddOrUpdateUniversity = async (university: { 
    name: string; 
    description: string; 
    color: string;
    whatsapp_url?: string;
    telegram_url?: string;
  }) => {
    if (!user) {
      onAuthRequired();
      return;
    }

    if (!features.canCreateUniversities && !profile?.is_admin) {
      alert('La creación de universidades está temporalmente deshabilitada');
      return;
    }

    try {
      if (editingUniversity) {
        await updateUniversity(editingUniversity.id, university);
      } else {
        await addUniversity(university);
      }
      setShowAddUniversity(false);
      setEditingUniversity(null);
    } catch (err) {
      console.error('Error managing university:', err);
      alert(err instanceof Error ? err.message : 'Error al gestionar universidad');
    }
  };

  const handleEditUniversity = (university: University) => {
    setEditingUniversity(university);
    setShowAddUniversity(true);
  };

  const filteredUniversities = universities.filter(university =>
    university.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    university.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="text-[#03E888] text-xl font-mono">Cargando universidades...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
        {error}
      </div>
    );
  }

  if (selectedUniversity) {
    const university = universities.find(u => u.id === selectedUniversity);
    if (!university) return null;

    return (
      <>
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => setSelectedUniversity(null)}
            className="text-[#03E888] hover:text-[#02c974] transition-colors font-mono"
          >
            ← Volver a universidades
          </button>
        </div>
        <CarList 
          universityId={selectedUniversity} 
          universityName={university.name}
          universityColor={university.color}
          whatsappUrl={university.whatsapp_url}
          telegramUrl={university.telegram_url}
          onAuthRequired={onAuthRequired} 
        />
      </>
    );
  }

  const canCreateUniversity = profile?.is_admin || features.canCreateUniversities;

  return (
    <>
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-sm -mx-4 sm:-mx-6 lg:-mx-8 px-4 sm:px-6 lg:px-8 py-4 border-b border-gray-800">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div>
              <h2 className="text-xl font-semibold text-white font-mono">Selecciona tu universidad</h2>
            </div>
            {canCreateUniversity ? (
              <button
                onClick={() => {
                  setEditingUniversity(null);
                  setShowAddUniversity(true);
                }}
                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-[#03E888] hover:bg-[#02c974] text-gray-900 px-4 py-2 rounded-lg transition-colors duration-300 font-mono"
              >
                <Plus className="w-5 h-5" />
                Añadir universidad
              </button>
            ) : (
              <a
                href="https://www.linkedin.com/in/lucas-losantos/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors duration-300 font-mono text-sm"
              >
                <Building2 className="w-4 h-4" />
                <span>¿Tu universidad no está? Contáctanos</span>
                <Linkedin className="w-4 h-4" />
              </a>
            )}
          </div>

          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Buscar universidad..."
          />
        </div>
      </div>

      <div className="pt-6">
        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
          {filteredUniversities.map((university) => (
            <UniversityCard
              key={university.id}
              university={university}
              onSelect={setSelectedUniversity}
              onEdit={handleEditUniversity}
              isAdmin={profile?.is_admin}
            />
          ))}
        </div>

        {filteredUniversities.length === 0 && searchQuery && (
          <div className="text-center py-12">
            <p className="text-gray-400 font-mono">No se encontraron universidades que coincidan con tu búsqueda.</p>
          </div>
        )}
      </div>

      {showAddUniversity && (
        <AddUniversityModal
          isOpen={showAddUniversity}
          onClose={() => {
            setShowAddUniversity(false);
            setEditingUniversity(null);
          }}
          onAdd={handleAddOrUpdateUniversity}
          initialData={editingUniversity}
        />
      )}
    </>
  );
}