import React, { useState } from 'react';
import { Plus, Users, Linkedin } from 'lucide-react';
import { GroupCard } from './GroupCard';
import { AddGroupModal } from './AddGroupModal';
import { CarList } from './CarList';
import { useGroups } from '../hooks/useGroups';
import { useProfile } from '../hooks/useProfile';
import { useFeatures } from '../contexts/FeatureContext';
import { SearchInput } from './SearchInput';
import type { Database } from '../lib/database.types';

type Group = Database['public']['Tables']['groups']['Row'] & {
  ride_count?: number;
};

interface Props {
  user: any;
  onAuthRequired: () => void;
}

export function GroupList({ user, onAuthRequired }: Props) {
  const { groups, loading, error, addGroup, updateGroup } = useGroups();
  const { profile } = useProfile(user);
  const { features } = useFeatures();
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [editingGroup, setEditingGroup] = useState<Group | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleAddOrUpdateGroup = async (group: { 
    name: string; 
    description: string; 
    color: string;
    whatsapp_url?: string;
    telegram_url?: string;
  }) => {
    if (!user) {
      onAuthRequired();
      return;
    }

    try {
      if (editingGroup) {
        await updateGroup(editingGroup.id, group);
      } else {
        await addGroup(group);
      }
      setShowAddGroup(false);
      setEditingGroup(null);
    } catch (err) {
      throw err instanceof Error ? err : new Error('Error al gestionar grupo');
    }
  };

  const handleEditGroup = (group: Group) => {
    setEditingGroup(group);
    setShowAddGroup(true);
  };

  const filteredGroups = groups.filter(group =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    group.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="text-[#03E888] text-xl font-mono">Cargando grupos...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
        {error}
      </div>
    );
  }

  if (selectedGroup) {
    const group = groups.find(g => g.id === selectedGroup);
    if (!group) return null;

    return (
      <>
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => setSelectedGroup(null)}
            className="text-[#03E888] hover:text-[#02c974] transition-colors font-mono"
          >
            ← Volver a grupos
          </button>
        </div>
        <CarList 
          universityId={selectedGroup} 
          universityName={group.name}
          universityColor={group.color}
          whatsappUrl={group.whatsapp_url}
          telegramUrl={group.telegram_url}
          onAuthRequired={onAuthRequired} 
        />
      </>
    );
  }

  const canCreateGroup = user !== null && features.canCreateGroups;

  return (
    <>
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-sm -mx-4 sm:-mx-6 lg:-mx-8 px-4 sm:px-6 lg:px-8 py-4 border-b border-gray-800">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div>
              <h2 className="text-xl font-semibold text-white font-mono">Selecciona tu grupo</h2>
            </div>
            {canCreateGroup ? (
              <button
                onClick={() => {
                  setEditingGroup(null);
                  setShowAddGroup(true);
                }}
                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-[#03E888] hover:bg-[#02c974] text-gray-900 px-4 py-2 rounded-lg transition-colors duration-300 font-mono"
              >
                <Plus className="w-5 h-5" />
                Añadir grupo
              </button>
            ) : (
              <button
                onClick={onAuthRequired}
                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors duration-300 font-mono text-sm"
              >
                <Users className="w-4 h-4" />
                <span>Inicia sesión para crear un grupo</span>
              </button>
            )}
          </div>

          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Buscar grupo..."
          />
        </div>
      </div>

      <div className="pt-6">
        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
          {filteredGroups.map((group) => (
            <GroupCard
              key={group.id}
              group={group}
              onSelect={setSelectedGroup}
              onEdit={handleEditGroup}
              isAdmin={profile?.is_admin}
            />
          ))}
        </div>

        {filteredGroups.length === 0 && searchQuery && (
          <div className="text-center py-12">
            <p className="text-gray-400 font-mono">No se encontraron grupos que coincidan con tu búsqueda.</p>
          </div>
        )}
      </div>

      {showAddGroup && (
        <AddGroupModal
          isOpen={showAddGroup}
          onClose={() => {
            setShowAddGroup(false);
            setEditingGroup(null);
          }}
          onAdd={handleAddOrUpdateGroup}
          initialData={editingGroup}
        />
      )}
    </>
  );
}