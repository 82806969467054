import React, { createContext, useContext, useState } from 'react';

interface FeatureFlags {
  canCreateGroups: boolean;
  canCreateUniversities: boolean;
}

interface FeatureContextType {
  features: FeatureFlags;
  toggleFeature: (feature: keyof FeatureFlags) => void;
}

const FeatureContext = createContext<FeatureContextType | undefined>(undefined);

const DEFAULT_FEATURES: FeatureFlags = {
  canCreateGroups: true, // Allow all users to create groups by default
  canCreateUniversities: false // Keep universities admin-only
};

export function FeatureProvider({ children }: { children: React.ReactNode }) {
  const [features, setFeatures] = useState<FeatureFlags>(DEFAULT_FEATURES);

  const toggleFeature = (feature: keyof FeatureFlags) => {
    setFeatures(prev => ({
      ...prev,
      [feature]: !prev[feature]
    }));
  };

  return (
    <FeatureContext.Provider value={{ features, toggleFeature }}>
      {children}
    </FeatureContext.Provider>
  );
}

export function useFeatures() {
  const context = useContext(FeatureContext);
  if (context === undefined) {
    throw new Error('useFeatures must be used within a FeatureProvider');
  }
  return context;
}